@use '@/styles/utils/mixins.scss' as *;

.errorBoundary {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $neutral01;
  padding: 20px;

  .errorContainer {
    max-width: 600px;
    width: 100%;
    background: $white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 32px;
  }

  .errorContent {
    text-align: center;

    h2 {
      color: $error;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 24px;
    }
  }

  .errorMessage {
    margin-bottom: 32px;

    p {
      color: $neutral08;
      margin-bottom: 16px;
      line-height: 1.5;
    }
  }
}
